<template>
    <div class="min-h-screen bg-gray-800 text-white">
      <Header />
      <main class="container mx-auto px-4 py-8">
        <h2 class="text-3xl font-bold mb-6">Token Overview</h2>
        <TokenTable />
      </main>
      <Footer />
    </div>
  </template>
  
  <script>
  import Header from '@/components/PageHeader.vue'
  import Footer from '@/components/PageFooter.vue'
  import TokenTable from '@/components/TokenTable.vue'
  
  export default {
    name: 'HomePage',
    components: {
      Header,
      Footer,
      TokenTable
    }
  }
  </script>