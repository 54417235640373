<template>
    <footer class="bg-gray-900 text-white p-4 mt-8">
      <div class="container mx-auto text-center">
        <p>&copy; 2024 Token Dashboard. All rights reserved.</p>
      </div>
    </footer>
  </template>
  
  <script>
  export default {
    name: 'PageFooter'
  }
  </script>