import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import axios from 'axios'
//import Vue from 'vue'

export default createStore({
  state: {
    isAuthenticated: false,
    user: null
  },
  mutations: {
    setAuthenticated(state, isAuthenticated) {
      state.isAuthenticated = isAuthenticated
    },
    setUser(state, user) {
      state.user = user
    }
  },
  actions: {
    async checkAuth({ commit }) {
      try {
        const response = await axios.get('/api/auth/status')
        commit('setAuthenticated', response.data.isAuthenticated)
        commit('setUser', response.data.user)
      } catch (error) {
        commit('setAuthenticated', false)
        commit('setUser', null)
      }
    },
    async login({ commit }, { email, password }) {
      try {
        const response = await axios.post('/api/auth/login', { email, password }, { withCredentials: true });
        if (response.data.message === 'Logged in successfully') {
          commit('setAuthenticated', true);
          return true;
        }
      } catch (error) {
        console.error('Login error:', error);
      }
      return false;
    },
    async logout({ commit }) {
      try {
        await fetch('/api/logout', { method: 'POST' });
        commit('setAuthenticated', false);
      } catch (error) {
        console.error('Logout error:', error);
      }
    }
  },
  plugins: [createPersistedState()]
}) 