<template>
    <div class="bg-gray-900 text-white p-6 rounded-lg">
      <table class="w-full">
        <thead>
          <tr class="text-gray-400 border-b border-gray-700">
            <th class="text-left py-3">#</th>
            <th class="text-left py-3">Name</th>
            <th class="text-right py-3">Active Holders</th>
            <th class="text-right py-3">4h Change</th>
            <th class="text-right py-3">24h Change</th>
            <th class="text-right py-3">Total Supply</th>
            <th class="text-right py-3">Network</th>
            <th class="text-right py-3">Last Updated</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(token, index) in tokens" :key="token._id" class="border-b border-gray-800">
            <td class="py-4">{{ index + 1 }}</td>
            <td class="py-4">
              <div class="flex items-center">
                <img v-if="token.logo" :src="token.logo" :alt="token.name" class="w-8 h-8 mr-3 rounded-full">
                <div>
                  <div class="font-medium">{{ token.name }}</div>
                  <div class="text-sm text-gray-400">{{ token.ticker }}</div>
                </div>
              </div>
            </td>
            <td class="text-right py-4">{{ formatNumber(token.totalAccounts) }}</td>
            <td class="text-right py-4">
            <span :class="getChangeClass(token.fourHourChange)">
              {{ formatChange(token.fourHourChange) }}
            </span>
          </td>
          <td class="text-right py-4">
            <span :class="getChangeClass(token.twentyFourHourChange)">
              {{ formatChange(token.twentyFourHourChange) }}
            </span>
          </td>
            <td class="text-right py-4">{{ formatNumber(token.totalSupply) }}</td>
            <td class="text-right py-4">{{ token.network }}</td>
            <td class="text-right py-4">
    <span :title="formatDate(token.lastUpdated)">{{ formatRelativeTime(token.lastUpdated) }}</span>
  </td>
          </tr>
        </tbody>
      </table>
      <div class="mt-4 flex justify-between items-center">
      <div>
        <span class="text-gray-400">Items per page</span>
        <select v-model="itemsPerPage" @change="fetchTokens" class="ml-2 bg-gray-800 text-white rounded p-1">
          <option>10</option>
          <option>20</option>
          <option>50</option>
        </select>
      </div>
      <div>
        <span class="text-gray-400">{{ paginationText }}</span>
        <button @click="prevPage" :disabled="currentPage === 1" class="ml-2 px-3 py-1 bg-gray-800 rounded">&lt;</button>
        <button @click="nextPage" :disabled="currentPage === totalPages" class="ml-2 px-3 py-1 bg-gray-800 rounded">&gt;</button>
      </div>
    </div>
  </div>
</template>
  
  <script>
  import axios from 'axios';
  import { formatDistanceToNow, parseISO } from 'date-fns';
  
  export default {
  name: 'TokenTable',
  data() {
    return {
      tokens: [],
      itemsPerPage: 10,
      currentPage: 1,
      totalTokens: 0,
      totalPages: 1
    }
  },
  computed: {
    paginationText() {
      const start = (this.currentPage - 1) * this.itemsPerPage + 1;
      const end = Math.min(this.currentPage * this.itemsPerPage, this.totalTokens);
      return `${start}-${end} of ${this.totalTokens}`;
    }
  },
  mounted() {
    this.fetchTokens();
  },
  methods: {
      formatNumber(num) {
        return num != null ? num.toLocaleString('en-US') : 'N/A';
      },
      formatDate(date) {
        return date ? new Date(date).toLocaleString() : 'N/A';
      },
      async fetchTokens() {
        if (this.isLoading) return;
      this.isLoading = true;
      try {
        console.log('Fetching tokens...');
        const response = await axios.get('/api/tokens', {
          params: {
            page: this.currentPage,
            limit: this.itemsPerPage
          }
        });
        console.log('Response:', response.data);
        this.tokens = response.data.tokens;
        this.totalTokens = response.data.totalTokens;
        this.totalPages = response.data.totalPages;
      } catch (error) {
        console.error('Error fetching tokens:', error);
      } finally {
        this.isLoading = false;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchTokens();
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.fetchTokens();
      }
    },
    formatRelativeTime(date) {
      return date ? formatDistanceToNow(parseISO(date), { addSuffix: true }) : 'N/A';
    },
    formatChange(change) {
      if (change === null) return '-';
      const arrow = change >= 0 ? '↑' : '↓';
      return `${arrow} ${Math.abs(change).toFixed(2)}%`;
    },
    getChangeClass(change) {
      if (change === null) return '';
      return change >= 0 ? 'text-green-500' : 'text-red-500';
    }
  },
  watch: {
    itemsPerPage() {
      this.currentPage = 1;
      this.fetchTokens();
    }
  }
}
  </script>