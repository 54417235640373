<template>
  <div class="min-h-screen bg-gray-800 text-white">
    <PageHeader />
    <main class="container mx-auto px-4 py-8">
      <h2 class="text-3xl font-bold mb-6">Admin Panel</h2>
      
      <!-- Add New Coin Button -->
      <button @click="showForm = true; editingToken = null" class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mb-6">
        Add New Coin
      </button>

      <!-- Existing Tokens Table -->
      <div class="overflow-x-auto">
        <table class="w-full">
          <thead>
            <tr class="text-left border-b border-gray-700">
              <th class="py-2">Name</th>
              <th class="py-2">Ticker</th>
              <th class="py-2">Network</th>
              <th class="py-2">Last Updated</th>
              <th class="py-2">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="token in tokens" :key="token._id" class="border-b border-gray-700">
              <td class="py-2">{{ token.name }}</td>
              <td class="py-2">{{ token.ticker }}</td>
              <td class="py-2">{{ token.network }}</td>
              <td class="py-2">{{ formatDate(token.lastUpdated) }}</td>
              <td class="py-2">
                <button @click="editToken(token)" class="bg-yellow-500 text-white px-2 py-1 rounded hover:bg-yellow-600 mr-2">Edit</button>
                <button @click="updateTokenHolders(token._id)" class="bg-green-500 text-white px-2 py-1 rounded hover:bg-green-600 mr-2">Update Holders</button>
                <button @click="removeToken(token._id)" class="bg-red-500 text-white px-2 py-1 rounded hover:bg-red-600">Remove</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Pagination -->
      <div class="mt-4 flex justify-between items-center">
        <button @click="prevPage" :disabled="currentPage === 1" class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:opacity-50">
          Previous
        </button>
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
        <button @click="nextPage" :disabled="currentPage === totalPages" class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:opacity-50">
          Next
        </button>
      </div>
    </main>

    <!-- Token Form Modal -->
    <div v-if="showForm" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div class="bg-gray-700 p-8 rounded-lg w-3/4 max-w-4xl">
        <h3 class="text-2xl mb-4">{{ editingToken ? 'Edit Token' : 'Add New Token' }}</h3>
        <form @submit.prevent="submitToken" class="grid grid-cols-2 gap-4">
          <!-- Left Column -->
          <div>
            <div class="mb-4">
              <label for="name" class="block mb-1">Name:</label>
              <input v-model="currentToken.name" id="name" type="text" required class="w-full p-2 bg-gray-600 rounded">
            </div>
            <div class="mb-4">
              <label for="ticker" class="block mb-1">Ticker:</label>
              <input v-model="currentToken.ticker" id="ticker" type="text" required class="w-full p-2 bg-gray-600 rounded">
            </div>
            <div class="mb-4">
              <label for="tokenAddress" class="block mb-1">Token Address:</label>
              <input v-model="currentToken.tokenAddress" id="tokenAddress" type="text" required class="w-full p-2 bg-gray-600 rounded">
            </div>
            <div class="mb-4">
              <label for="tokenProgramId" class="block mb-1">Token Program ID:</label>
              <input v-model="currentToken.tokenProgramId" id="tokenProgramId" type="text" required class="w-full p-2 bg-gray-600 rounded">
            </div>
            <div class="mb-4">
              <label for="logo" class="block mb-1">Logo URL:</label>
              <input v-model="currentToken.logo" id="logo" type="url" class="w-full p-2 bg-gray-600 rounded">
            </div>
            <div class="mb-4">
              <label for="network" class="block mb-1">Network:</label>
              <select v-model="currentToken.network" id="network" required class="w-full p-2 bg-gray-600 rounded">
                <option value="SOL">Solana</option>
                <option value="ETH">Ethereum</option>
                <option value="BASE">Base</option>
              </select>
            </div>
            <div class="mb-4">
              <label for="frequency" class="block mb-1">Update Frequency:</label>
              <select v-model="currentToken.frequency" id="frequency" required class="w-full p-2 bg-gray-600 rounded">
                <option value="4h">4 hours</option>
                <option value="6h">6 hours</option>
                <option value="12h">12 hours</option>
                <option value="24h">24 hours</option>
              </select>
            </div>
          </div>
          
          <!-- Right Column -->
          <div>
            <div class="mb-4">
              <label for="coingeckoId" class="block mb-1">CoinGecko ID (optional):</label>
              <input v-model="currentToken.coingeckoId" id="coingeckoId" type="text" class="w-full p-2 bg-gray-600 rounded">
            </div>
            <div class="mb-4">
              <label for="coincodexId" class="block mb-1">CoinCodex ID (optional):</label>
              <input v-model="currentToken.coincodexId" id="coincodexId" type="text" class="w-full p-2 bg-gray-600 rounded">
            </div>
            <div class="mb-4">
              <label for="description" class="block mb-1">Description:</label>
              <textarea v-model="currentToken.description" id="description" class="w-full p-2 bg-gray-600 rounded" rows="3"></textarea>
            </div>
            <div class="mb-4">
              <label for="links" class="block mb-1">Links (comma-separated):</label>
              <input v-model="currentToken.links" id="links" type="text" class="w-full p-2 bg-gray-600 rounded">
            </div>
          </div>

          <div class="col-span-2 flex justify-end">
            <button type="submit" class="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 mr-2">
              {{ editingToken ? 'Update Token' : 'Add Token' }}
            </button>
            <button @click="showForm = false" type="button" class="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>

    <PageFooter />
  </div>
</template>

<script>
import axios from 'axios';
import PageHeader from '@/components/PageHeader.vue';
import PageFooter from '@/components/PageFooter.vue';

export default {
  name: 'AdminPanel',
  components: {
    PageHeader,
    PageFooter
  },
  data() {
    return {
      tokens: [],
      currentToken: this.getEmptyToken(),
      editingToken: null,
      showForm: false,
      currentPage: 1,
      totalPages: 1,
      itemsPerPage: 50
    }
  },
  methods: {
    getEmptyToken() {
      return {
        name: '',
        ticker: '',
        tokenAddress: '',
        tokenProgramId: '',
        network: 'SOL',
        coingeckoId: '',
        coincodexId: '',
        logo: '',
        description: '',
        links: '',
        frequency: '4h'
      }
    },
    formatDate(date) {
      return date ? new Date(date).toLocaleString() : 'N/A';
    },
    async fetchTokens() {
  try {
    const response = await axios.get('/api/tokens/admin', {
      params: {
        page: this.currentPage,
        limit: this.itemsPerPage
      }
    });
    this.tokens = response.data.tokens;
    this.totalPages = response.data.totalPages;
    this.totalTokens = response.data.totalTokens;
  } catch (error) {
    console.error('Error fetching tokens:', error);
    if (error.response && error.response.status === 401) {
      // Redirect to login page if unauthorized
      this.$router.push('/login');
    }
  }
},
    async submitToken() {
      try {
        if (this.editingToken) {
          await axios.put(`/api/tokens/${this.editingToken._id}`, this.currentToken);
        } else {
          await axios.post('/api/tokens', this.currentToken);
        }
        this.showForm = false;
        this.currentToken = this.getEmptyToken();
        this.editingToken = null;
        this.fetchTokens();
      } catch (error) {
        console.error('Error submitting token:', error);
        alert('Error submitting token: ' + (error.response?.data?.message || error.message));
      }
    },
    async removeToken(id) {
      if (confirm('Are you sure you want to remove this token?')) {
        try {
          await axios.delete(`/api/tokens/${id}`);
          this.fetchTokens();
        } catch (error) {
          console.error('Error removing token:', error);
        }
      }
    },
    editToken(token) {
      this.editingToken = token;
      this.currentToken = { ...token };
      this.showForm = true;
    },
    async updateTokenHolders(tokenId) {
      try {
        await axios.post(`/api/tokens/${tokenId}/update-holders`);
        alert('Token holder update initiated. This may take some time.');
      } catch (error) {
        console.error('Error updating token holders:', error);
        alert('Error updating token holders. Please try again.');
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetchTokens();
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        this.fetchTokens();
      }
    }
  },
  mounted() {
    this.fetchTokens();
  }
}
</script>