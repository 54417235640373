<template>
  <div id="app" class="bg-gray-800 min-h-screen">
    <router-view></router-view>
  </div>
</template>

<script>
//import axios from 'axios';
import { mapActions } from 'vuex'

export default {
  name: 'App',
  methods: {
    ...mapActions(['checkAuth'])
    // async checkAuth() {
    //   try {
    //     const response = await axios.get('/api/auth/status', { withCredentials: true });
    //     if (response.data.isAuthenticated) {
    //       this.$store.commit('setAuthenticated', true);
    //       this.$store.commit('setUser', response.data.user);
    //     }
    //   } catch (error) {
    //     console.error('Authentication check failed:', error);
    //     this.$store.commit('setAuthenticated', false);
    //     this.$store.commit('setUser', null);
    //   }
    // }
  },
  created() {
    this.checkAuth();
  }
}
</script>

<style>
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

body {
  font-family: 'Arial', sans-serif;
}
</style>