<template>
    <div>
      <h2>Login</h2>
      <form @submit.prevent="login">
        <input v-model="email" type="email" placeholder="Email" required>
        <input v-model="password" type="password" placeholder="Password" required>
        <button type="submit">Login</button>
      </form>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue'
  import { useStore } from 'vuex'
  import { useRouter } from 'vue-router'
  
export default {
  setup() {
    const store = useStore()
    const router = useRouter()
    const email = ref('')
    const password = ref('')

    const login = async () => {
      try {
        await store.dispatch('login', { email: email.value, password: password.value })
        await store.dispatch('checkAuth')
        if (store.state.isAuthenticated) {
          router.push('/admin')
        } else {
          alert('Login failed')
        }
      } catch (error) {
        console.error('Login error:', error)
        alert('Login failed')
      }
    }

    return { email, password, login }
  }
}
</script>